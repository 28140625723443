// Body
$body-bg: #ECEFF1;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
// 700 system
$blue:   #1976D2;
$indigo: #303F9F;
$purple: #7B1FA2;
$pink:   #C2185B;
$red:    #D32F2F;
$orange: #F57C00;
$yellow: #FBC02D;
$green:  #388E3C; // applicant
$teal:   #00796B;
$cyan:   #0097A7; // recruiter

$primary:   $blue !default;
$secondary: #757575 !default;
$success:   $green !default;
$info:      $cyan !default;
$warning:   $orange !default;
$danger:    $red !default;
$light:     #F5F5F5 !default;
$dark:      #212121 !default;

$embed-responsive-aspect-ratios: (
  (1200, 630),
  (21 9),
  (16 9),
  (4 3),
  (1 1)
) !default;

$sizes: (
    33: 33%,
    66: 66%
);
