// Fonts
@import 'font';

// Variables
@import 'variables';

// Bootstrap
@import 'admin-lte/build/scss/adminlte';

.btn-primary:hover {
    color: #fff !important;
}
.btn-outline-primary:hover {
    color: #fff !important;
}

.card-header button[data-toggle="collapse"].collapsed::after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f107";
    display: block;
    float: right;
}
.card-header button[data-toggle="collapse"]::after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f106";
    display: block;
    float: right;
}

.top-0 {
    top: 0;
}
.bottom-0 {
    bottom: 0;
}
.left-0 {
    left: 0;
}
.right-0 {
    right: 0;
}

.object-fit-fill {
    object-fit: fill;
}
.object-fit-contain {
    object-fit: contain;
}
.object-fit-cover {
    object-fit: cover;
}
.object-fit-none {
    object-fit: none;
}
.object-fit-scale-down {
    object-fit: scale-down;
}

.opacity-0 {
    opacity: 0;
}
.opacity-25 {
    opacity: .25;
}
.opacity-50 {
    opacity: .5;
}
.opacity-75 {
    opacity: .75;
}
.opacity-100 {
    opacity: 1;
}

.bg-facebook {
    background-color: #4267b2;
}
.bg-linkedin {
    background-color: #0e76a8;
}
